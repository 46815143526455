import { Injectable, inject } from '@angular/core';

import { IRegistrationCurrentOrganisationAddress } from 'cde-fe-organization-registration-dialog';
import { OrganizationService } from './organization.service';
import { map, mergeMap, take } from 'rxjs';
import { IAddressSearchResponse } from '../models/organisation.model';

@Injectable({
  providedIn: 'root',
})
export class AddressService {
  private organisationService = inject(OrganizationService);

  public getAddressDetails$(language: string, country: string, address: Partial<IRegistrationCurrentOrganisationAddress>) {
    country = country ?? 'de';
    language = language ?? 'de';
    return this.organisationService.getAddressAutocomplete({
      language,
      countrySet: country,
      query: this.formatAddress(address),
    }).pipe(
      take(1),
      mergeMap(response => {
        const addressSearchResult: IAddressSearchResponse = response?.[0];
        return this.organisationService.getAddressDetails({
          id: addressSearchResult.id,
          language,
          countrySet: country
        }); // .pipe(map(addressDetails => addressDetails));
      })
    );
  }

  public getFormattedAddress$(language: string, country: string, address: Partial<IRegistrationCurrentOrganisationAddress>) {
    return this.getAddressDetails$(language, country, address).pipe(
      map(addressDetails => addressDetails.description ?? this.formatAddress(address))
    );
  }

  private formatAddress(address: Partial<IRegistrationCurrentOrganisationAddress>) {
    let autocompleteInput = '';
    if (!address) {
      return '';
    }

    autocompleteInput = this.addToAddressString(autocompleteInput, address.street ?? null);
    autocompleteInput = this.addToAddressString(autocompleteInput, address.houseNumber ?? null);
    autocompleteInput = this.extendWithComma(autocompleteInput);
    autocompleteInput = this.addToAddressString(autocompleteInput, address.postalCode ?? null);
    autocompleteInput = this.addToAddressString(autocompleteInput, address.city ?? null);
    if ((address.suburb?.length ?? 0) > 0) {
      autocompleteInput = this.extendWithComma(autocompleteInput);
      autocompleteInput = this.addToAddressString(autocompleteInput, address.suburb ?? null);
    }

    if ((address.state?.length ?? 0) > 0) {
      autocompleteInput = this.extendWithComma(autocompleteInput);
      autocompleteInput = this.addToAddressString(autocompleteInput, address.state?.toUpperCase() ?? null);
    }

    return autocompleteInput;
  }

  private addToAddressString(address: string, element: string | null) {
    if (element?.length ?? 0 > 0) {
      if (address.length === 0) {
        address = element as string;
      } else {
        address = `${address} ${element}`;
      }
    }

    return address;
  }

  private extendWithComma(autocompleteInput: string) {
    if (autocompleteInput.length) {
      autocompleteInput = `${autocompleteInput},`;
    }

    return autocompleteInput;
  }
}
