import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, finalize, map, switchMap, take } from 'rxjs/operators';
import * as RootState from '..';
import * as FilterActions from './filter.actions';

@Injectable()
export class FilterEffects {
  private store = inject<Store<RootState.IState>>(Store<RootState.IState>);
  private actions$ = inject(Actions);
  alarmSectionFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FilterActions.ALARM_SECTION_FILTERS),
      switchMap((data: { payload: [] }) =>
        of(data).pipe(
          take(1),
          map((filter: any) => new FilterActions.AlarmSectionFiltersSuccess(filter.payload)),
          catchError((error) => of(new FilterActions.AlarmSectionFiltersFailure(error))),
          finalize(() => {
            this.store.dispatch(new FilterActions.AlarmSectionFiltersComplete());
          })
        )
      )
    )
  );

  analyticsFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FilterActions.ANALYTICS_FILTERS),
      switchMap((data: { payload: [] }) =>
        of(data).pipe(
          take(1),
          map((filter: any) => new FilterActions.AnalyticsFiltersSuccess(filter.payload)),
          catchError((error) => of(new FilterActions.AnalyticsFiltersFailure(error))),
          finalize(() => {
            this.store.dispatch(new FilterActions.AnalyticsFiltersComplete());
          })
        )
      )
    )
  );

  dealerSelectionStepFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FilterActions.DEALER_SELECTION_STEP_FILTERS),
      switchMap((data: { payload: [] }) =>
        of(data).pipe(
          take(1),
          map((filter: any) => new FilterActions.DealerSelectionStepFiltersSuccess(filter.payload)),
          catchError((error) => of(new FilterActions.DealerSelectionStepFiltersFailure(error))),
          finalize(() => {
            this.store.dispatch(new FilterActions.DealerSelectionStepFiltersComplete());
          })
        )
      )
    )
  );

  machineComparisonModalFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FilterActions.MACHINE_COMPARISON_MODAL_FILTERS),
      switchMap((data: { payload: [] }) =>
        of(data).pipe(
          take(1),
          map((filter: any) => new FilterActions.MachineComparisonModalFiltersSuccess(filter.payload)),
          catchError((error) => of(new FilterActions.MachineComparisonModalFiltersFailure(error))),
          finalize(() => {
            this.store.dispatch(new FilterActions.MachineComparisonModalFiltersComplete());
          })
        )
      )
    )
  );

  machineOptimizeFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FilterActions.MACHINE_OPTIMIZE_FILTERS),
      switchMap((data: { payload: [] }) =>
        of(data).pipe(
          take(1),
          map((filter: any) => new FilterActions.MachineOptimizeFiltersSuccess(filter.payload)),
          catchError((error) => of(new FilterActions.MachineOptimizeFiltersFailure(error))),
          finalize(() => {
            this.store.dispatch(new FilterActions.MachineOptimizeFiltersComplete());
          })
        )
      )
    )
  );

  machinesOverviewFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FilterActions.MACHINES_OVERVIEW_FILTERS),
      switchMap((data: { payload: [] }) =>
        of(data).pipe(
          take(1),
          map((filter: any) => new FilterActions.MachinesOverviewFiltersSuccess(filter.payload)),
          catchError((error) => of(new FilterActions.MachinesOverviewFiltersFailure(error))),
          finalize(() => {
            this.store.dispatch(new FilterActions.MachinesOverviewFiltersComplete());
          })
        )
      )
    )
  );

  sentFilesFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FilterActions.SENT_FILES_FILTERS),
      switchMap((data: { payload: [] }) =>
        of(data).pipe(
          take(1),
          map((filter: any) => new FilterActions.SentFilesFiltersSuccess(filter.payload)),
          catchError((error) => of(new FilterActions.SentFilesFiltersFailure(error))),
          finalize(() => {
            this.store.dispatch(new FilterActions.SentFilesFiltersComplete());
          })
        )
      )
    )
  );

  receivedFilesFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FilterActions.RECEIVED_FILES_FILTERS),
      switchMap((data: { payload: [] }) =>
        of(data).pipe(
          take(1),
          map((filter: any) => new FilterActions.ReceivedFilesFiltersSuccess(filter.payload)),
          catchError((error) => of(new FilterActions.ReceivedFilesFiltersFailure(error))),
          finalize(() => {
            this.store.dispatch(new FilterActions.ReceivedFilesFiltersComplete());
          })
        )
      )
    )
  );

  machineLicenseFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FilterActions.MACHINE_LICENSE_FILTERS),
      switchMap((data: { payload: [] }) =>
        of(data).pipe(
          take(1),
          map((filter: any) => new FilterActions.MachineLicenseFiltersSuccess(filter.payload)),
          catchError((error) => of(new FilterActions.MachineLicenseFiltersFailure(error))),
          finalize(() => {
            this.store.dispatch(new FilterActions.MachineLicenseFiltersComplete());
          })
        )
      )
    )
  );
}
