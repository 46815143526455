import { Injectable, inject } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  IDashboard,
  IWidget,
  IWidgetConfiguration,
  IPatchDashboard,
  IWidgetImpl,
  IDashboardImpl,
  IWeatherInformation,
  IAddressInformation,
} from '../models/dashboard.model';
import { ServiceCall } from './service-call.service';
import { environment } from '@environments/environment';

export const DASHBOARD_URL = 'v1/dashboards';
export const MAP_URL = 'v3/map-services';
export const MAP_V1_URL = 'v1/map-services';

@Injectable()
export class DashboardService {
  private serviceCall = inject(ServiceCall);
  sidebarStatusSubject$: Subject<boolean> = new Subject();
  private dashboardUrl = `${environment.apiBaseUrl}/${DASHBOARD_URL}`;
  private mapUrl = `${environment.apiBaseUrl}/${MAP_URL}`;
  private mapWeatherInfoUrl = `${environment.apiBaseUrl}/${MAP_V1_URL}`;

  createDashboard(dashboard: IDashboard) {
    return this.serviceCall.post(`${this.dashboardUrl}`, dashboard).pipe(
      map((response) => {
        if (response.statusCode === 200) {
          return response.data as IDashboardImpl;
        }
      })
    );
  }

  getDashboard(id: string) {
    return this.serviceCall.get(`${this.dashboardUrl}/${id}`).pipe(
      map((response) => {
        if (response.statusCode === 200) {
          return response.data as IDashboardImpl;
        }
      })
    );
  }

  getAllDashboards() {
    return this.serviceCall.get(`${this.dashboardUrl}`).pipe(
      map((response) => {
        if (response.statusCode === 200) {
          return response.data.content;
        }
      })
    );
  }

  getAllDashboardsAfterOrgCreation() {
    // fromReg=1 add query param to disable caching for this call. So we can
    // ensure the correct creation of widgets after an org is created
    return this.serviceCall.get(`${this.dashboardUrl}?fromOrgReg=1`).pipe(
      map((response) => {
        if (response.statusCode === 200) {
          return response.data.content;
        }
      })
    );
  }

  deleteDashboard(id: string) {
    return this.serviceCall.delete(`${this.dashboardUrl}/${id}`).pipe(
      map((response) => {
        if (response.statusCode === 204) {
          return id;
        }
      })
    );
  }

  patchDashboard(id: string, patchData: IPatchDashboard) {
    return this.serviceCall.patch(`${this.dashboardUrl}/${id}`, patchData).pipe(
      map((response) => {
        if (response.statusCode === 200) {
          return true;
        }
        return false;
      })
    );
  }

  createWidget(dashboardId: string, widget: IWidget) {
    return this.serviceCall
      .post(`${this.dashboardUrl}/${dashboardId}/widgets`, widget)
      .pipe(map((response) => response.data as IWidgetImpl));
  }

  getWidget(dashboardId: string, widgetId: string) {
    return this.serviceCall.get(`${this.dashboardUrl}/${dashboardId}/widgets/${widgetId}`).pipe(
      map((response) => {
        if (response.statusCode === 200) {
          return response.data as IWidgetImpl;
        }
      })
    );
  }

  getAllWidgets(dashboardId: string) {
    return this.serviceCall.get(`${this.dashboardUrl}/${dashboardId}/widgets`).pipe(
      map((response) => {
        if (response.statusCode === 200) {
          return response.data.content;
        }
      })
    );
  }

  deleteWidget(dashboardId: string, widgetId: string) {
    return this.serviceCall.delete(`${this.dashboardUrl}/${dashboardId}/widgets/${widgetId}`).pipe(
      map((response) => {
        if (response.statusCode === 204) {
          return widgetId;
        }
        return null;
      })
    );
  }

  patchWidget(dashboardId: string, widgetId: string, patchData: IWidgetConfiguration) {
    return this.serviceCall
      .patch(`${this.dashboardUrl}/${dashboardId}/widgets/${widgetId}`, patchData)
      .pipe(map((response) => response.data as IWidgetImpl));
  }

  getWeatherInfo(language: string, latitude: number, longitude: number, id: string) {
    return this.serviceCall
      .get(`${this.mapWeatherInfoUrl}/weather-information?language=${language}&latitude=${latitude}&longitude=${longitude}`)
      .pipe(
        map((response) => {
          if (response.statusCode === 200) {
            const info = {
              currentWeather: response.data.currentWeather,
              weatherForecasts: response.data.weatherForecasts,
              id: id,
            };

            return info as IWeatherInformation;
          }
        })
      );
  }

  getAdresses(language: string, countrySet: string, query: string) {
    return this.serviceCall.get(`${this.mapUrl}/address-search?language=${language}&countrySet=${countrySet}&query=${query}`).pipe(
      map((response) => {
        if (response.statusCode === 200) {
          return response.data as IAddressInformation;
        }
      })
    );
  }

  postLocations(fs: boolean, items: string) {
    if (fs) {
      localStorage.setItem('cardLocfs', items);
    } else {
      localStorage.setItem('cardLoc', items);
    }

    return of(items);
  }

  getLocations(fs: boolean) {
    if (fs) {
      return of(localStorage.getItem('cardLocfs'));
    }

    return of(localStorage.getItem('cardLoc'));
  }

  checkNavbarStatus(status: boolean): void {
    this.sidebarStatusSubject$.next(status);
  }
}
