import { Injectable, inject } from '@angular/core';
import { map } from 'rxjs/operators';
import { IDealerData, IDealerFilterResponse } from '../models/dealer.model';
import { ServiceCall } from './service-call.service';
import { environment } from '@environments/environment';
import { INearestDealersByAddressRequest } from 'cde-fe-organization-registration-dialog/lib/models/dealer.model';
import { INearestDealersResponse } from 'cde-fe-organization-registration-dialog';

@Injectable({
  providedIn: 'root',
})
export class DealerService {
  private serviceCall = inject(ServiceCall);
  private dealerBaseUrl = `${environment.apiBaseUrl}/v2`;

  getDealer(dealerId: string, language: string, dealerLocatorId?: string | null) {
    // services wants dealer ID with leading zeros, otherwise dealer is not
    // found
    dealerId = dealerId.padStart(10, '0');
    return this.serviceCall
      .get(`${this.dealerBaseUrl}/dealers/${dealerId}`, dealerLocatorId ? { language, dealerLocatorId } : { language })
      .pipe(
        map((response) => response.data as IDealerData)
      );
  }

  getDealersByAddress(request: INearestDealersByAddressRequest, language: string) {
    // services wants dealer ID with leading zeros, otherwise dealer is not
    // found
    return this.serviceCall
      .post(`${this.dealerBaseUrl}/dealers/nearest-address-dealers`, request, { language })
      .pipe(
        map((response) => response.data as INearestDealersResponse)
      );
  }

  getDealerFilters() {
    return this.serviceCall
      .get(`${this.dealerBaseUrl}/dealer-filters`)
      .pipe(
        map((response) => response.data as IDealerFilterResponse)
      );
  }

  getTopParentDealer(dealerId: string, language: string) {
    // services wants dealer ID with leading zeros, otherwise dealer is not
    // found
    dealerId = dealerId.padStart(10, '0');
    return this.serviceCall
      .get(`${this.dealerBaseUrl}/dealers/${dealerId}/dealership-parent`, { language })
      .pipe(
        map((response) => response.data as IDealerData)
      );
  }
}
