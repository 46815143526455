import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { Injectable, inject } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

@Injectable()
export class LocalizedPaginator implements MatPaginatorIntl {
  private translateService = inject(TranslateService);
  private breakpointObserver = inject(BreakpointObserver);
  // this is needed to implement MatPaginatorIntl
  public changes = new Subject<void>();

  public firstPageLabel = this.translateService.instant('generic.table.pagination.first-page');
  public itemsPerPageLabel = this.translateService.instant('generic.table.pagination.entries-per-page');
  public lastPageLabel = this.translateService.instant('generic.table.pagination.last-page');
  public nextPageLabel = this.translateService.instant('generic.table.pagination.next-page');
  public previousPageLabel = this.translateService.instant('generic.table.pagination.previous-page');

  constructor(
  ) {
    // needs no unsubscribe because this class will live as long as the
    // application
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
    ]).subscribe((breakpointState: BreakpointState) => {
      this.itemsPerPageLabel = breakpointState.matches ? '' : this.translateService.instant('generic.table.pagination.entries-per-page');
      this.changes.next();
    });
  }

  // implementation is taken from original getRangeLabel implementation
  // see: https://github.com/angular/components/blob/main/src/material/paginator/paginator-intl.ts
  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0 || pageSize === 0) {
      return this.translateService.instant('generic.table.pagination.range-0', { length });
    }

    length = Math.max(length, 0);
    const startIndex = page * pageSize;

    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return this.translateService.instant('generic.table.pagination.range-1', { startIndex: startIndex + 1, endIndex, length });
  }
}
