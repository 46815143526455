
import { Injectable, inject } from '@angular/core';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import * as FeatureToggleActions from './feature-toggle.actions';
import {
  switchMap,
  map,
  catchError,
  finalize,
} from 'rxjs/operators';

import * as RootState from '..';
import * as OrganisationActions from '../organisations/organisations.actions';
import { FeatureToggleService } from '@app/shared/services/feature-toggle.service';
import { IFeatureToggle } from '@app/shared/models/feature-toggle.model';

@Injectable()
export class FeatureToggleEffects {
  private store = inject<Store<RootState.IState>>(Store<RootState.IState>);
  private actions$ = inject(Actions);
  private featureToggleService = inject(FeatureToggleService);
  getFeatureToggles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FeatureToggleActions.GET_FEATURE_TOGGLES),
      switchMap(() =>
        this.featureToggleService.getFeatureToggles().pipe(
          map(
            (featureToggles: IFeatureToggle[]) =>
              new FeatureToggleActions.GetFeatureTogglesSuccess(featureToggles)
          ),
          catchError((error) =>
            of(new FeatureToggleActions.GetFeatureTogglesFailure(error))
          ),
          finalize(() => {
            this.store.dispatch(new FeatureToggleActions.GetFeatureTogglesComplete());
          })
        )
      )
    )
  );

  refreshAfterCurrentOrgChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganisationActions.SET_CURRENT_ORGANISATION_SUCCESS),
      map(() => new FeatureToggleActions.GetFeatureToggles())
    )
  );
}
