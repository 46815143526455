import { Inject, Injectable, PLATFORM_ID, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, finalize, map, mergeMap, switchMap, take } from 'rxjs/operators';
import { ConnectionsService } from '@app/shared/services/connections.service';
import * as ConnectionsActions from './connections.actions';
import * as RootState from '..';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import {
  IConnection,
  IConnectionWithScopedCouplings,
  ICoupling,
  IDataConnectInboundAuth,
  IDataConnectInboundCouplingList,
  IDataConnectInboundEquipmentList,
  IPartner, SortedConnections,
} from '@app/shared/models/connections.model';
import { isPlatformBrowser } from '@angular/common';
import { AlertService } from '@app/cde-toast/services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { TOASTR_ALERT_OPTIONS } from '@app/cde-toast/utils/toastr-alert-options';
import { ToasterType } from '@app/cde-toast/utils/toastr-options';

@Injectable()
export class ConnectionsEffects {
  private store = inject<Store<RootState.IState>>(Store<RootState.IState>);
  private actions$ = inject(Actions);
  private connectionService = inject(ConnectionsService);
  private alertService = inject(AlertService);
  private translateService = inject(TranslateService);
  private platformId = inject<number | string>(PLATFORM_ID);
  timeZoneWatcher: Subscription;
  alertOptions = {
    ...TOASTR_ALERT_OPTIONS,
    timeOut: 5000,
  };

  list$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConnectionsActions.CONNECTIONS),
      switchMap((action: ConnectionsActions.ConnectionList) =>
        this.connectionService.getConnections(action.payload.languageCode).pipe(
          map(connections => this.sortConnectionsByActiveCouplings(connections)),
          map((connections: SortedConnections) => new ConnectionsActions.ConnectionsSuccess(connections)),
          catchError((error) => {
            this.alertService.showError(
              this.translateService.instant('couplings.notifications.load_connections.failure'),
              this.translateService.instant('alert_message.try_again'),
              'alert-error-icon',
              this.alertOptions
            );
            return of(new ConnectionsActions.ConnectionsFailure(error));
          }),
          finalize(() => {
            this.store.dispatch(new ConnectionsActions.ConnectionsComplete());
          })
        )
      )
    )
  );

  sortConnectionsByActiveCouplings(connections: IConnection[]): SortedConnections {
    let connectionsWithActiveCouplings: IConnection[] = [];
    let connectionsWithoutActiveCouplings: IConnection[] = [];
    connections.forEach((connection: IConnection) => {
      connection.couplings?.some((c: ICoupling) => c.state === 'active') ?
      connectionsWithActiveCouplings.push(connection) :
      connectionsWithoutActiveCouplings.push(connection)
    })
    return { active: connectionsWithActiveCouplings, inactive: connectionsWithoutActiveCouplings };
  }

  getConnection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConnectionsActions.CONNECTION),
      switchMap((action: ConnectionsActions.Connection) =>
        this.connectionService.getConnection(action.payload.id, action.payload.languageCode).pipe(
          map((connections: IConnectionWithScopedCouplings) => new ConnectionsActions.ConnectionSuccess(connections)),
          catchError((error) => {
            this.alertService.showError(
              this.translateService.instant('couplings.notifications.load_connection.failure'),
              this.translateService.instant('alert_message.try_again'),
              'alert-error-icon',
              this.alertOptions
            );
            return of(new ConnectionsActions.ConnectionFailure(error));
          }),
          finalize(() => {
            this.store.dispatch(new ConnectionsActions.ConnectionComplete());
          })
        )
      )
    )
  );

  getContentOfCoupling$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConnectionsActions.COUPLING_CONSENT),
      switchMap((action: ConnectionsActions.CouplingConsent) =>
        this.connectionService.getCouplingConsent(action.payload.connectionId, action.payload.coupling.id).pipe(
          map(
            (result: any) =>
              new ConnectionsActions.CouplingConsentSuccess({
                couplingId: <string>action.payload.coupling.id,
                consent: result,
              })
          ),
          catchError((error) => of(new ConnectionsActions.CouplingConsentFailure(error))),
          finalize(() => {
            this.store.dispatch(new ConnectionsActions.CouplingConsentComplete());
          })
        )
      )
    )
  );

  getPartnerLogos$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConnectionsActions.CONNECTIONS_SUCCESS),
      map((action: ConnectionsActions.ConnectionsSuccess) => action.payload),
      switchMap((connections: SortedConnections) =>
        this.getLogosForConnections([...connections.active, ...connections.inactive]).pipe(
          map((resultList: Map<string, string>) => new ConnectionsActions.ConnectionPartnerImagesLoadedSuccess(resultList)),
          catchError((error) => of(new ConnectionsActions.ConnectionPartnerImagesLoadedFailure(error))),
          finalize(() => {
            this.store.dispatch(new ConnectionsActions.ConnectionPartnerImagesLoadedCompleted());
          })
        )
      )
    )
  );

  getPartnerLogo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConnectionsActions.CONNECTION_SUCCESS),
      map((action: ConnectionsActions.ConnectionSuccess) => action.payload),
      switchMap((connection: { partner: IPartner; couplingForScope: Map<string, ICoupling[] | null | undefined> }) =>
        this.connectionService.getPartnerLogo(connection.partner.id).pipe(
          map((resultList: any) => new ConnectionsActions.ConnectionPartnerImageLoadedSuccess(resultList)),
          catchError((error) => of(new ConnectionsActions.ConnectionPartnerImageLoadedFailure(error))),
          finalize(() => {
            this.store.dispatch(new ConnectionsActions.ConnectionPartnerImageLoadedCompleted());
          })
        )
      )
    )
  );

  revokeCoupling$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConnectionsActions.REVOKE_COUPLING_OF_CONNECTION),
      switchMap((data: { payload: { coupling: ICoupling; connection: IConnection, languageCode: string }; type: string }) =>
        this.connectionService.revokeCoupling(data.payload.connection, data.payload.coupling).pipe(
          map(() => new ConnectionsActions.RevokeCouplingSuccess()),
          catchError((error) => of(new ConnectionsActions.RevokeCouplingFailure(error))),
          finalize(() => {
            this.store.dispatch(new ConnectionsActions.RevokeCouplingComplete());
            this.store.dispatch(new ConnectionsActions.Connection({ id: data.payload.connection.id, languageCode: data.payload.languageCode }));
          })
        )
      )
    )
  );

  getDataConnectCouplings = createEffect(() =>
    this.actions$.pipe(
      ofType(ConnectionsActions.DATACONNECT_GET_COUPLINGS),
      mergeMap((action: ConnectionsActions.DataConnectGetCouplings) =>
        this.connectionService.getDataConnectCouplings(action.payload.connectionId).pipe(
          map((couplings: IDataConnectInboundCouplingList) => new ConnectionsActions.DataConnectGetCouplingsSuccess(couplings)),
          catchError((error) => of(new ConnectionsActions.DataConnectGetCouplingsFailure(error))),
          finalize(() => this.store.dispatch(new ConnectionsActions.DataConnectGetCouplingsComplete()))
        )
      )
    )
  );

  createDataConnectInboundCoupling = createEffect(() =>
    this.actions$.pipe(
      ofType(ConnectionsActions.DATACONNECT_INBOUND_COUPLING_AUTH),
      map((action: ConnectionsActions.DataConnectInboundCouplingAuth) => action.payload),
      switchMap((data: { connectionId: string; state?: string; code?: string; error?: string; errorDescription?: string }) =>
        this.connectionService.getDataConnectInboundAuth(data.connectionId, data.state, data.code, data.error, data.errorDescription).pipe(
          take(1),
          map((dataConnectInboundAuth: IDataConnectInboundAuth) => {
            if (dataConnectInboundAuth && dataConnectInboundAuth.redirectUri) {
              if (isPlatformBrowser(this.platformId)) {
                if (!data.state && !data.code) {
                  window.open(dataConnectInboundAuth.redirectUri, '_blank');
                } else {
                  window.location.href = dataConnectInboundAuth.redirectUri;
                }
              }
              return;
            }

            return new ConnectionsActions.DataConnectInboundCouplingAuthSuccess(dataConnectInboundAuth);
          }),
          catchError((error) => {
            this.alertService.showError(
              this.translateService.instant('couplings.dataconnect.inbound.notifications.create.failure'),
              this.translateService.instant('alert_message.try_again'),
              'alert-error-icon',
              this.alertOptions
            );
            return of(new ConnectionsActions.DataConnectInboundCouplingAuthFailure(error));
          }),
          finalize(() => {
            this.store.dispatch(new ConnectionsActions.DataConnectInboundCouplingAuthComplete());
          })
        )
      )
    )
  );

  fetchDataConnectEquipment = createEffect(() =>
    this.actions$.pipe(
      ofType(ConnectionsActions.DATACONNECT_FETCH_EQUIPMENT),
      mergeMap((action: ConnectionsActions.DataConnectFetchEquipment) =>
        this.connectionService.fetchEquipment(action.payload.connectionId, action.payload.containerId, action.payload.couplingId).pipe(
          map((data: IDataConnectInboundEquipmentList) => {
            data.equipment.forEach((equipment) => {
              equipment.isSelected = false;
            });
            return new ConnectionsActions.DataConnectFetchEquipmentSuccess(data);
          }),
          catchError((error) => {
            this.alertService.showError(
              this.translateService.instant('couplings.dataconnect.inbound.notifications.fetch_equipment.failure'),
              this.translateService.instant('alert_message.try_again'),
              'alert-error-icon',
              this.alertOptions
            );
            return of(new ConnectionsActions.DataConnectFetchEquipmentFailure(error));
          }),
          finalize(() => this.store.dispatch(new ConnectionsActions.DataConnectFetchEquipmentComplete()))
        )
      )
    )
  );

  putDataConnectContainerCreate = createEffect(() =>
    this.actions$.pipe(
      ofType(ConnectionsActions.DATACONNECT_PUT_CONTAINER_CREATE),
      mergeMap((action: ConnectionsActions.DataConnectPutContainerCreate) =>
        this.connectionService
          .putDataConnectContainer(action.payload.connectionId, action.payload.couplingId, action.payload.putContainer)
          .pipe(
            map(() => new ConnectionsActions.DataConnectPutContainerCreateSuccess()),
            catchError((error) => {
              this.alertService.showError(
                this.translateService.instant('couplings.dataconnect.inbound.notifications.create.failure'),
                this.translateService.instant('alert_message.try_again'),
                'alert-error-icon',
                this.alertOptions
              );
              return of(new ConnectionsActions.DataConnectPutContainerCreateFailure(error));
            }),
            finalize(() => this.store.dispatch(new ConnectionsActions.DataConnectPutContainerCreateComplete()))
          )
      )
    )
  );

  putDataConnectContainerUpdate = createEffect(() =>
    this.actions$.pipe(
      ofType(ConnectionsActions.DATACONNECT_PUT_CONTAINER_UPDATE),
      mergeMap((action: ConnectionsActions.DataConnectPutContainerUpdate) =>
        this.connectionService
          .putDataConnectContainer(action.payload.connectionId, action.payload.couplingId, action.payload.putContainer)
          .pipe(
            map(() => new ConnectionsActions.DataConnectPutContainerUpdateSuccess({
              couplingId: action.payload.couplingId,
            })),
            catchError((error) => {
              this.alertService.showError(
                this.translateService.instant('couplings.dataconnect.inbound.notifications.create.failure'),
                this.translateService.instant('alert_message.try_again'),
                'alert-error-icon',
                this.alertOptions
              );
              return of(new ConnectionsActions.DataConnectPutContainerUpdateFailure(error));
            }),
            finalize(() => this.store.dispatch(new ConnectionsActions.DataConnectPutContainerUpdateComplete()))
          )
      )
    )
  );

  deleteDataConnectCoupling = createEffect(() =>
    this.actions$.pipe(
      ofType(ConnectionsActions.DATACONNECT_DELETE_COUPLING),
      mergeMap((action: ConnectionsActions.DataConnectDeleteCoupling) =>
        this.connectionService.deleteDataConnectCoupling(action.payload.connectionId, action.payload.couplingId).pipe(
          map(() => {
            this.alertService.callSnackbarSuccess(
              this.translateService.instant('couplings.dataconnect.inbound.notifications.delete.success'),
              '',
              ToasterType.snackbarSuccess,
              {},
            );
            return new ConnectionsActions.DataConnectDeleteCouplingSuccess();
          }),
          catchError((error) => {
            this.alertService.showError(
              this.translateService.instant('couplings.dataconnect.inbound.notifications.delete.failure'),
              this.translateService.instant('alert_message.try_again'),
              'alert-error-icon',
              this.alertOptions
            );
            return of(new ConnectionsActions.DataConnectDeleteCouplingFailure(error));
          }),
          finalize(() => this.store.dispatch(new ConnectionsActions.DataConnectDeleteCouplingComplete()))
        )
      )
    )
  );

  getLogosForConnections(connections: IConnection[]): Observable<Map<string, string>> {
    return forkJoin(connections.map((c) => this.connectionService.getPartnerLogo(c.id))).pipe(
      map(logoResponses => {
        let logosByConnectionId = new Map<string, string>();
        logoResponses.forEach(logoResponse => logosByConnectionId.set(logoResponse.id, logoResponse.logoUrl));
        return logosByConnectionId as Map<string, string>;
      }
      )
    )
  }
}
