import { Injectable, inject } from '@angular/core';

import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, catchError, finalize, mergeMap, tap } from 'rxjs/operators';

import * as RootState from '@app/shared/state';
import * as MachineDigitalDeliveryAdviceActions from './machine.digital-delivery-advice.actions';

import { MachineDigitalDeliveryAdviceService } from '@app/shared/services/machine.digital-delivery-advice.service';
import { IDigitalDeliveryAdvice, IDigitalDeliveryAdviceLegalTexts, IDigitalDeliveryAdviceRequest, IGetDigitalDeliveryAdviceResponse, IUpdateDigitalDeliveryAdvice } from '@app/shared/models/machine.digital-delivery-advice.model';
import { ServiceCallResponse } from 'cde-fe-organization-registration-dialog/lib/models/service-call.model';
import { AlertService } from '@app/cde-toast/services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { TOASTR_ALERT_OPTIONS } from '@app/cde-toast/utils/toastr-alert-options';
import { IOnboardClaasMachineError } from '@app/shared/models/machines.model';

@Injectable()
export class MachineDigitalDeliveryAdviceEffects {
  private store = inject<Store<RootState.IState>>(Store<RootState.IState>);
  private actions$ = inject(Actions);
  private digitalDeliveryAdviceService = inject(MachineDigitalDeliveryAdviceService);
  private alertService = inject(AlertService);
  private translateService = inject(TranslateService);
  getDigitalDeliveryAdvice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MachineDigitalDeliveryAdviceActions.GET_DIGITAL_DELIVERY_ADVICE),
      map((action: MachineDigitalDeliveryAdviceActions.GetDigitalDeliveryAdvice) => action.request),
      mergeMap((request: IDigitalDeliveryAdviceRequest) =>
        this.digitalDeliveryAdviceService.getDigitalDeliveryAdvice(request).pipe(
          map((digitalDeliveryAdviceResponse: IGetDigitalDeliveryAdviceResponse) =>
            new MachineDigitalDeliveryAdviceActions.GetDigitalDeliveryAdviceSuccess(request, digitalDeliveryAdviceResponse)),
          catchError((error) => of(new MachineDigitalDeliveryAdviceActions.GetDigitalDeliveryAdviceFailure(error))),
          finalize(() => {
            this.store.dispatch(new MachineDigitalDeliveryAdviceActions.GetDigitalDeliveryAdviceComplete());
          })
        )
      )
    )
  );

  updateDigitalDeliveryAdvice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MachineDigitalDeliveryAdviceActions.UPDATE_DIGITAL_DELIVERY_ADVICE),
      mergeMap((updateDigitalDeliveryAdviceAction: MachineDigitalDeliveryAdviceActions.UpdateDigitalDeliveryAdvice) =>
        this.digitalDeliveryAdviceService
          .updateDigitalDeliveryAdvice(
            updateDigitalDeliveryAdviceAction.request,
            updateDigitalDeliveryAdviceAction.updateDigitalDeliveryAdvice
          )
          .pipe(
            map(
              () =>
                new MachineDigitalDeliveryAdviceActions.UpdateDigitalDeliveryAdviceSuccess(
                  updateDigitalDeliveryAdviceAction.request,
                  updateDigitalDeliveryAdviceAction.updateDigitalDeliveryAdvice
                )
            ),
            catchError((error) => of(new MachineDigitalDeliveryAdviceActions.UpdateDigitalDeliveryAdviceFailure(error))),
            finalize(() => {
              this.store.dispatch(new MachineDigitalDeliveryAdviceActions.UpdateDigitalDeliveryAdviceComplete());
            })
          )
      )
    )
  );

  updateDigitalDeliveryAdviceError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MachineDigitalDeliveryAdviceActions.UPDATE_DIGITAL_DELIVERY_ADVICE_FAILURE),
        map((action: MachineDigitalDeliveryAdviceActions.UpdateDigitalDeliveryAdviceFailure) => action.payload as ServiceCallResponse),
        tap((errorResponse: ServiceCallResponse) => {
          if (errorResponse?.failCode === 409) {
            const error = errorResponse.data as IOnboardClaasMachineError;
            this.alertService.showError(
              this.translateService.instant('add_products.' + error.localMessageKey),
              this.translateService.instant('alert_message.error'),
              'alert-error-icon',
              this.alertOptions
            );
          } else {
            this.alertService.showError(
              this.translateService.instant('global.error_occurred'),
              this.translateService.instant('alert_message.error'),
              'alert-error-icon',
              this.alertOptions
            );
          }
        })
      ),
    { dispatch: false }
  );

  getDigitalDeliveryAdviceLegalTexts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MachineDigitalDeliveryAdviceActions.GET_DIGITAL_DELIVERY_ADVICE_LEGAL_TEXTS),
      map((action: MachineDigitalDeliveryAdviceActions.GetDigitalDeliveryAdviceLegalTexts) => action.language),
      mergeMap((language: string) =>
        this.digitalDeliveryAdviceService.getDigitalDeliveryAdviceLegalTexts(language).pipe(
          map((legalTexts: IDigitalDeliveryAdviceLegalTexts) =>
            new MachineDigitalDeliveryAdviceActions.GetDigitalDeliveryAdviceLegalTextsSuccess(legalTexts)),
          catchError((error) => of(new MachineDigitalDeliveryAdviceActions.GetDigitalDeliveryAdviceLegalTextsFailure(error))),
          finalize(() => {
            this.store.dispatch(new MachineDigitalDeliveryAdviceActions.GetDigitalDeliveryAdviceLegalTextsComplete());
          })
        )
      )
    )
  );

  private alertOptions = TOASTR_ALERT_OPTIONS;
}
