import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, finalize, map, switchMap, mergeMap, take, withLatestFrom, tap } from 'rxjs/operators';
import * as uuid from 'uuid';

import { IAlarmServiceEvents } from './../../models/machines.model';
import { DashboardService } from '../../services/dashboard.service';
import { MachinesService } from '../../services/machines.service';

import * as DashboardActions from './dashboard.actions';
import * as OrganisationActions from '../organisations/organisations.actions';
import * as MachineOverviewActions from '../machine-overview/machines.actions';
import * as RootState from '..';

import {
  IAddDeleteWidgetData,
  ICreateDashboardWidgetRequest,
  IDashboard,
  IDashboardImpl,
  IPatchDashboard,
  IUpdateWidgetRequest,
  IWeatherInformation,
  IWeatherWidgetLocation,
  IWidgetImpl,
  IWidgetLocations,
} from '@app/shared/models/dashboard.model';
import { IMachineServiceEventsResponse, Machine } from '@app/shared/models/machines.model';
import { TOASTR_ALERT_OPTIONS } from '@app/cde-toast/utils/toastr-alert-options';
import {
  IDealerDataRegistrationResponse,
  IRegistrationCurrentOrganisationAddress,
  ShopKey,
  User,
} from 'cde-fe-organization-registration-dialog';
import { parseWidgetConfiguration, writeWidgetConfiguration } from '@app/shared/utils/widget-configuration-handler';

import { RegistrationDialogService } from '@app/shared/services/registration-dialog.service';
import { IGetOrganisationDealerWidgetContext } from '@app/shared/models/shops.model';
import { AddressService } from '@app/shared/services/address.service';
import { decodeBase64ToObject, encodeObjectToBase64 } from '@app/shared/utils/base64';

type UpdateDealerWidgetAfterNotFound = [
  IGetOrganisationDealerWidgetContext,
  IDashboardImpl[] | undefined,
  string | undefined,
  string | undefined
];

@Injectable()
export class DashboardEffects {
  private store = inject<Store<RootState.IState>>(Store<RootState.IState>);
  private actions$ = inject(Actions);
  private dashboardService = inject(DashboardService);
  private machinesService = inject(MachinesService);
  private registrationDialogService = inject(RegistrationDialogService);
  private addressService = inject(AddressService);
  alertOptions = {
    ...TOASTR_ALERT_OPTIONS,
    timeOut: 3000,
  };

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.CREATE),
      map((action: DashboardActions.Create) => action.payload),
      switchMap((payload: IDashboard) =>
        this.dashboardService.createDashboard(payload).pipe(
          map((dashboard: IDashboardImpl | undefined) => new DashboardActions.CreateSuccess(dashboard)),
          catchError((error) => of(new DashboardActions.CreateFailure(error))),
          finalize(() => {
            this.store.dispatch(new DashboardActions.CreateComplete());
          })
        )
      )
    )
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.DELETE),
      map((action: DashboardActions.Delete) => action.payload),
      switchMap((payload: string) =>
        this.dashboardService.deleteDashboard(payload).pipe(
          map((id: string | undefined) => new DashboardActions.DeleteSuccess(id)),
          catchError((error) => of(new DashboardActions.DeleteFailure(error))),
          finalize(() => {
            this.store.dispatch(new DashboardActions.DeleteComplete());
          })
        )
      )
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.UPDATE),
      map((action: DashboardActions.Update) => action.payload),
      switchMap((payload: { id: string; value: IPatchDashboard }) =>
        this.dashboardService.patchDashboard(payload.id, payload.value).pipe(
          map((success: boolean) => new DashboardActions.UpdateSuccess(success, { id: payload.id, ...payload.value })),
          catchError((error) => of(new DashboardActions.UpdateFailure(error))),
          finalize(() => {
            this.store.dispatch(new DashboardActions.UpdateComplete());
          })
        )
      )
    )
  );

  list$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.GETBYID),
      map((action: DashboardActions.GetByID) => action.payload),
      switchMap((payload: string) =>
        this.dashboardService.getDashboard(payload).pipe(
          map((dashboard: IDashboardImpl | undefined) => new DashboardActions.GetByIDSuccess(dashboard)),
          catchError((error) => of(new DashboardActions.GetByIDFailure(error))),
          finalize(() => {
            this.store.dispatch(new DashboardActions.GetByIDComplete());
          })
        )
      )
    )
  );

  getAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.GETALL),
      map((action: DashboardActions.GetAll) => action.dealerRegistrationResponse),
      mergeMap((dealerRegistrationResponse: IDealerDataRegistrationResponse | undefined) =>
        this.dashboardService.getAllDashboards().pipe(
          map(
            (dashboards: Array<IDashboardImpl> | undefined) =>
              new DashboardActions.GetAllSuccess(dashboards ?? [], dealerRegistrationResponse)
          ),
          catchError((error) => of(new DashboardActions.GetAllFailure(error))),
          finalize(() => {
            this.store.dispatch(new DashboardActions.GetAllComplete());
          })
        )
      )
    )
  );

  getAllSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DashboardActions.GETALL_SUCCESS),
        map((action: DashboardActions.GetAllSuccess) => action.dealerRegistrationResponse),
        tap((dealerRegistrationResponse?: IDealerDataRegistrationResponse) => {
          if (dealerRegistrationResponse == null) {
            return;
          }

          this.store.dispatch(new DashboardActions.UpdateDealerWidgetAfterChange(dealerRegistrationResponse));
        })
      ),
    { dispatch: false }
  );

  createWidget$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.CREATE_WIDGET),
      map((action: DashboardActions.CreateWidget) => action.payload),
      switchMap((payload: ICreateDashboardWidgetRequest) =>
        this.dashboardService.createWidget(payload.dashboardId, payload.value).pipe(
          map((widget: IWidgetImpl | undefined) => new DashboardActions.CreateWidgetSuccess(widget, payload.dashboardId)),
          catchError((error) => of(new DashboardActions.CreateWidgetFailure(error))),
          finalize(() => {
            this.store.dispatch(new DashboardActions.CreateWidgetComplete());
          })
        )
      )
    )
  );

  deleteWidget$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.DELETE_WIDGET),
      map((action: DashboardActions.DeleteWidget) => action.payload),
      mergeMap((payload: IAddDeleteWidgetData) =>
        this.dashboardService.deleteWidget(payload.dashboardId, payload.widgetId).pipe(
          map((id: string | null) => new DashboardActions.DeleteWidgetSuccess(payload)),
          catchError((error) => of(new DashboardActions.DeleteWidgetFailure(error))),
          finalize(() => {
            this.store.dispatch(new DashboardActions.DeleteWidgetComplete());
          })
        )
      )
    )
  );

  updateWidget$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.UPDATE_WIDGET),
      map((action: DashboardActions.UpdateWidget) => action.payload),
      switchMap((payload: IUpdateWidgetRequest) =>
        this.dashboardService.patchWidget(payload.dashboardId, payload.widgetId, payload.value).pipe(
          map((widget: IWidgetImpl) => new DashboardActions.UpdateWidgetSuccess(widget, payload.dashboardId)),
          catchError((error) => of(new DashboardActions.UpdateWidgetFailure(error))),
          finalize(() => {
            this.store.dispatch(new DashboardActions.UpdateWidgetComplete());
          })
        )
      )
    )
  );

  listWidget$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.GETBYID_WIDGET),
      map((action: DashboardActions.GetWidgetByID) => action.payload),
      switchMap((payload: { dashboardId: string; widgetId: string }) =>
        this.dashboardService.getWidget(payload.dashboardId, payload.widgetId).pipe(
          map((widget: IWidgetImpl | undefined) => new DashboardActions.GetWidgetByIDSuccess(widget)),
          catchError((error) => of(new DashboardActions.GetWidgetByIDFailure(error))),
          finalize(() => {
            this.store.dispatch(new DashboardActions.GetWidgetByIDComplete());
          })
        )
      )
    )
  );

  getAllWidgets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.GETALL_WIDGET),
      map((action: DashboardActions.GetAllWidget) => action.payload),
      switchMap((payload: string) =>
        this.dashboardService.getAllWidgets(payload).pipe(
          map((widgets: Array<IWidgetImpl> | undefined) => new DashboardActions.GetAllWidgetSuccess(widgets)),
          catchError((error) => of(new DashboardActions.GetAllWidgetFailure(error))),
          finalize(() => {
            this.store.dispatch(new DashboardActions.GetAllWidgetComplete());
          })
        )
      )
    )
  );

  getWeatherInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.GETWEATHER_INFO),
      map((action: DashboardActions.GetWeatherInfo) => action.payload),
      mergeMap((payload: { language: string; latitude: number; longitude: number; id: string }) =>
        this.dashboardService.getWeatherInfo(payload.language, payload.latitude, payload.longitude, payload.id).pipe(
          map((weatherInfo: IWeatherInformation | undefined) => new DashboardActions.GetWeatherInfoSuccess(weatherInfo)),
          catchError((error) => of(new DashboardActions.GetWeatherInfoFailure(error))),
          finalize(() => {
            this.store.dispatch(new DashboardActions.GetWeatherInfoComplete(payload.id));
          })
        )
      )
    )
  );

  machinesList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.MACHINES_LIST),
      switchMap(() =>
        this.machinesService.listWithoutArchivedAndWithDataLogger().pipe(
          take(1),
          map((machinesList: Machine[]) => new DashboardActions.MachinesListSuccess(machinesList)),
          catchError((error) => of(new DashboardActions.MachinesListFailure(error))),
          finalize(() => {
            this.store.dispatch(new DashboardActions.MachinesListComplete());
          })
        )
      )
    )
  );

  digitalDeliveryPendingMachinesList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.DIGITAL_DELIVERY_PENDING_MACHINES_LIST),
      mergeMap(() =>
        this.machinesService.listWithDigitalDeliveryPending().pipe(
          map((machinesList: Machine[]) => new DashboardActions.DigitalDeliveryPendingMachinesListSuccess(machinesList)),
          catchError((error) => of(new DashboardActions.DigitalDeliveryPendingMachinesListFailure(error))),
          finalize(() => {
            this.store.dispatch(new DashboardActions.DigitalDeliveryPendingMachinesListComplete());
          })
        )
      )
    )
  );

  merviceEventsList = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.SERVICEEVENTS_LIST),
      map((action: DashboardActions.ServiceEventsList) => action.payload),
      switchMap((payload: { timeZone: string; language: string }) =>
        this.machinesService.getAllMachineServiceEvents(payload.timeZone, payload.language).pipe(
          map((serviceEvents: IMachineServiceEventsResponse | undefined) => new DashboardActions.ServiceEventsListSuccess(serviceEvents)),
          catchError((error) => of(new DashboardActions.ServiceEventsListFailure(error))),
          finalize(() => {
            this.store.dispatch(new DashboardActions.ServiceEventsListComplete());
          })
        )
      )
    )
  );

  machineAlarmsList = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.ALARMEVENTS_LIST),
      map((action: DashboardActions.AlarmEventsList) => action.payload),
      switchMap((payload: { measuredAtLocalDate: string; timeZone: string; language: string }) =>
        this.machinesService.getAllMachineAlarmEvents(payload.measuredAtLocalDate, payload.timeZone, payload.language).pipe(
          map((serviceEvents: IAlarmServiceEvents[] | undefined) => new DashboardActions.AlarmEventsListSuccess(serviceEvents)),
          catchError((error) => of(new DashboardActions.AlarmEventsListFailure(error))),
          finalize(() => {
            this.store.dispatch(new DashboardActions.AlarmEventsListComplete());
          })
        )
      )
    )
  );

  updateOld$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.UPDATE),
      map((action: DashboardActions.UpdateOld) => action.payload),
      switchMap((payload: IWidgetLocations) =>
        this.dashboardService.postLocations(payload['fs'], payload['items']).pipe(
          take(1),
          map((widgetlocs: string) => new DashboardActions.UpdateOldSuccess(widgetlocs)),
          catchError((error) => of(new DashboardActions.UpdateOldFailure(error))),
          finalize(() => {
            this.store.dispatch(new DashboardActions.UpdateOldComplete());
          })
        )
      )
    )
  );

  listOld$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.WIDGETLOCATIONS),
      map((action: DashboardActions.WidgetLocations) => action.payload),
      switchMap((payload: boolean) =>
        this.dashboardService.getLocations(payload).pipe(
          map((widgetlocs: string | null) => new DashboardActions.WidgetLocationsSuccess(widgetlocs)),
          catchError((error) => of(new DashboardActions.WidgetLocationsFailure(error))),
          finalize(() => {
            this.store.dispatch(new DashboardActions.WidgetLocationsComplete());
          })
        )
      )
    )
  );

  getDashboardsAfterOrgCreation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.GET_DASHBOARDS_AFTER_ORG_CREATION),
      switchMap((action: DashboardActions.GetDashboardsAfterOrgCreation) =>
        this.dashboardService.getAllDashboardsAfterOrgCreation().pipe(
          map((dashboards: Array<IDashboardImpl> | undefined) => new DashboardActions.GetAllSuccess(dashboards ?? [])),
          catchError((error) => of(new DashboardActions.GetAllFailure(error))),
          finalize(() => {
            this.store.dispatch(new DashboardActions.GetAllComplete());

            // trigger update of widgets on dashboard with dealer, shop and
            // weather data
            this.store.dispatch(
              new DashboardActions.UpdateDashboardAfterOrgRegistration(
                action.newOrgUuid,
                action.shops,
                action.dealer,
                action.organisationAddress
              )
            );
          })
        )
      )
    )
  );

  updateDashboardAfterOrgRegistration$ = createEffect(
    () =>
      this.actions$.pipe(
        // After switch to reg. dialog v0.0.115 the registration is done after
        // completing the last step, not after completing step 4
        ofType(DashboardActions.UPDATE_DASHBOARD_AFTER_ORG_REGISTRATION),
        withLatestFrom(this.store),
        map(
          ([action, store]: [DashboardActions.UpdateDashboardAfterOrgRegistration, RootState.IState]) =>
            [action, store.dashboard.getAllSuc, store.auth.user] as [
              DashboardActions.UpdateDashboardAfterOrgRegistration,
              IDashboardImpl[] | undefined,
              User | null
            ]
        ),
        tap(
          ([action, dashboards, user]: [
            DashboardActions.UpdateDashboardAfterOrgRegistration,
            IDashboardImpl[] | undefined,
            User | null
          ]) => {
            this.updateWidgetsInDashboardAfterOrgRegistration(
              dashboards,
              action.newOrgUuid,
              action.shops,
              action.dealer.id,
              action.organisationAddress,
              user
            );
          }
        )
      ),
    { dispatch: false }
  );

  updateDealerWidgetAfterDealerChange$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrganisationActions.REGISTER_ORGANISATION_DEALER_SUCCESS, DashboardActions.UPDATE_DEALER_WIDGET_AFTER_CHANGE),
        withLatestFrom(this.store),
        map(
          ([action, store]: [
            OrganisationActions.RegisterOrganisationDealerSuccess | DashboardActions.UpdateDealerWidgetAfterChange,
            RootState.IState
          ]) =>
            [action.payload, store.dashboard.getAllSuc, store.organisations.currentOrganisation?.id] as [
              IDealerDataRegistrationResponse,
              IDashboardImpl[] | undefined,
              string | undefined
            ]
        ),
        tap(([payload, dashboards, orgId]: [IDealerDataRegistrationResponse, IDashboardImpl[] | undefined, string | undefined]) => {
          if (dashboards == null || dashboards.length === 0) {
            this.store.dispatch(new DashboardActions.GetAll(payload));
            return;
          }

          const dealer = payload.registeredDealer;
          const orgDashboard = dashboards?.find((dashboard) => dashboard.organizationId === orgId);
          if (!orgDashboard) {
            throw new Error(`Org with id ${orgId} not found`);
          }

          // remove unneeded properties
          const { id, updateDashboard } = this.extractUpdateDashboard(orgDashboard);
          if (dealer?.dealerId) {
            updateDashboard.widgets = this.updateDealerWidgetWithNewDealer(updateDashboard.widgets, dealer?.dealerId);

            this.store.dispatch(
              new DashboardActions.Update({
                id: id,
                value: updateDashboard,
              })
            );
          }
        })
      ),
    { dispatch: false }
  );

  // this effect is called to update a dealer widget when the dealer associated
  // with a dealer widget is not connected to the current organisation anymore.
  // Then the current dealer of the organisation is calculated and stored in the
  // widget configuration
  updateDealerWidgetAfterDealerNotFound$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DashboardActions.UPDATE_DEALER_WIDGET_AFTER_NOT_FOUND),
        withLatestFrom(this.store),
        map(
          ([action, store]: [DashboardActions.UpdateDealerWidgetAfterNotFound, RootState.IState]) =>
            [
              action.context,
              store.dashboard.getAllSuc,
              store.organisations.currentOrganisation?.id,
              store.organisations.currentOrganisation?.dealer?.id,
            ] as UpdateDealerWidgetAfterNotFound
        ),
        tap(([context, dashboards, orgId, currentOrgDealerId]: UpdateDealerWidgetAfterNotFound) => {
          if (this.dealerWidgetAfterDealerNotFoundWasUpdated) {
            return;
          }

          if (dashboards == null || dashboards.length === 0) {
            this.store.dispatch(new DashboardActions.GetAll());
            return;
          }

          // find the dashboard with the faulty dealer widget
          const contextDashboard = dashboards?.find((dashboard) => dashboard.id === context.dashboardId);
          if (!contextDashboard) {
            throw new Error(`Org with id ${orgId} not found`);
          }

          // remove unneeded properties from the dashboard and extract just
          // the update payload
          const { id, updateDashboard } = this.extractUpdateDashboard(contextDashboard);
          if (currentOrgDealerId) {
            // store the new current organisation dealer in the faulty widget
            updateDashboard.widgets = this.updateDealerWidgetWithNewDealer(updateDashboard.widgets, currentOrgDealerId, context.widgetId);

            // store if it was already tried to update the faulty widget to
            // not end in an infinite loop of not finding the widget dealer
            // and updating the widgets dealer again and again
            this.dealerWidgetAfterDealerNotFoundWasUpdated = true;
            this.store.dispatch(
              new DashboardActions.Update({
                id: id,
                value: updateDashboard,
              })
            );
          }
        })
      ),
    { dispatch: false }
  );

  removeMachineWidgetsAfterRemovingMachine$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MachineOverviewActions.REMOVE_MACHINE_SUCCESS),
        withLatestFrom(this.store),
        map(([action, store]: [MachineOverviewActions.RemoveMachineSuccess, RootState.IState]) => [
          action.payload.machineId,
          store.dashboard.getAllSuc,
        ] as [string, IDashboardImpl[] | undefined]),
        tap(([machineId, dashboards]: [string, IDashboardImpl[] | undefined]) => {
          // nothing to be done
          if (dashboards == null || dashboards.length === 0) {
            return;
          }

          // look for Analytics and KPI widgets which are machine dependent
          // remove widgets that are configured for the current machine given by
          // machineId
          const currentUserDashboard = dashboards[0];
          currentUserDashboard.widgets
            .filter((widget) => widget.name.includes('MachineAnalyticsWidget') || widget.name.includes('MachineKpiWidget'))
            .filter((widget) => {
              const configuration = parseWidgetConfiguration(widget.configuration);
              const widgetMachine: Machine | null = configuration?.machine ? decodeBase64ToObject(configuration.machine) : null;

              return widgetMachine?.id === machineId;
            })
            .forEach((widget) =>
              this.store.dispatch(new DashboardActions.DeleteWidget({ dashboardId: currentUserDashboard.id, widgetId: widget.id }))
            );
        })
      ),
    { dispatch: false }
  );

  private dealerWidgetAfterDealerNotFoundWasUpdated = false;

  private updateWidgetsInDashboardAfterOrgRegistration(
    dashboards: IDashboardImpl[] | undefined,
    newOrgId: string,
    registrationShops: ShopKey[],
    dealerId: string | null,
    organisationAddress: IRegistrationCurrentOrganisationAddress,
    user: User | null
  ) {
    const orgDashboard = dashboards?.find((dashboard) => dashboard.organizationId === newOrgId);
    if (!orgDashboard) {
      return new DashboardActions.UpdateFailure('missing-org');
    }

    // remove unneeded properties
    const { id, updateDashboard } = this.extractUpdateDashboard(orgDashboard);

    // find default shop widget
    updateDashboard.widgets = this.updateShopWidgetAfterOrgRegistration(updateDashboard.widgets, registrationShops);
    updateDashboard.widgets = this.updateDealerWidgetAfterOrgRegistration(updateDashboard.widgets, dealerId);
    this.updateWeatherWidgetAfterOrgRegistration(
      updateDashboard.widgets,
      organisationAddress,
      user,
      (resultWeatherWidgets: IWidgetImpl[]) => {
        updateDashboard.widgets = resultWeatherWidgets;

        this.store.dispatch(
          new DashboardActions.Update({
            id: id,
            value: updateDashboard,
          })
        );
      }
    );
  }

  private updateShopWidgetAfterOrgRegistration(widgets: IWidgetImpl[], registrationShops: ShopKey[]) {
    // find default dealer widget
    const shopWidgetIdx = this.findDemoWidget(widgets, 'ShopWidget');

    // create new shop widgets
    const shopWidgets = registrationShops.map(
      (registrationShop) =>
      ({
        name: 'ShopWidget',
        configuration: writeWidgetConfiguration({
          selectedShop: registrationShop,
        }),
        x: 1,
        // calculate starting y in column 1
        y: this.calculateNewYIndexForWidget(widgets) + 1,
        id: uuid.v4(),
      } as IWidgetImpl)
    );

    return this.updateDashboardWidget(widgets, shopWidgets, shopWidgetIdx);
  }

  private updateDealerWidgetAfterOrgRegistration(widgets: IWidgetImpl[], dealerId: string | null) {
    // find default dealer widget
    const dealerWidgetIdx = this.findDemoWidget(widgets, 'DealerWidget');

    // create dealer widget with configuration from registration dealer data
    const updatedDealerWidget = {
      ...widgets[dealerWidgetIdx],
      configuration: writeWidgetConfiguration({
        dealerId: dealerId,
      }),
    };

    return this.updateDashboardWidget(widgets, updatedDealerWidget, dealerWidgetIdx);
  }

  private updateWeatherWidgetAfterOrgRegistration(
    widgets: IWidgetImpl[],
    organisationAddress: IRegistrationCurrentOrganisationAddress | null,
    user: User | null,
    setResultWeatherWidget: (resultWeatherWidgets: IWidgetImpl[]) => void
  ) {
    if (organisationAddress == null || user == null) {
      return;
    }
    // find default dealer widget
    const weatherWidgetIdx = this.findDemoWidget(widgets, 'WeatherWidget');

    if (organisationAddress.latitude !== 0 && organisationAddress.longitude !== 0) {
      // take(1) -> no unsubscribe needed
      this.updateWeatherWidgetInWidgetList(widgets, weatherWidgetIdx, organisationAddress, user.user_metadata.language).subscribe(
        (updatedWidgets) => {
          setResultWeatherWidget(updatedWidgets);
        }
      );
    }

    const query = this.registrationDialogService.createAutocompleteQuery(organisationAddress);
    this.registrationDialogService.getLatLonForOrganisationAddress(
      query,
      organisationAddress,
      user.user_metadata.country,
      user.user_metadata.language
    ).subscribe(
      (resultAddress: IRegistrationCurrentOrganisationAddress) => {
        // take(1) -> no unsubscribe needed
        this.updateWeatherWidgetInWidgetList(widgets, weatherWidgetIdx, resultAddress, user.user_metadata.language).subscribe(
          (updatedWidgets) => {
            setResultWeatherWidget(updatedWidgets);
          }
        );
      }
    );
  }

  private updateWeatherWidgetInWidgetList(
    widgets: IWidgetImpl[],
    weatherWidgetIdx: number,
    organisationAddress: IRegistrationCurrentOrganisationAddress,
    language: string
  ) {
    // take(1) -> no unsubscribe needed
    return this.addressService.getFormattedAddress$(language, organisationAddress.country, organisationAddress).pipe(
      map((formattedAddress) => {
        const weatherLocations = [
          {
            name: formattedAddress,
            latitude: organisationAddress.latitude,
            longitude: organisationAddress.longitude,
          } as IWeatherWidgetLocation,
        ];

        const updatedWeatherWidget = {
          ...widgets[weatherWidgetIdx],
          configuration: writeWidgetConfiguration({
            locations: encodeObjectToBase64(weatherLocations),
          }),
        };

        return this.updateDashboardWidget(widgets, updatedWeatherWidget, weatherWidgetIdx);
      })
    );
  }

  private findDemoWidget(widgets: IWidgetImpl[], widgetName: string) {
    return widgets?.findIndex(
      (widget) =>
        (widget.configuration === 'NONE' && widget.name === widgetName) ||
        (widget.configuration !== 'NONE' && widget.name === widgetName && widgetName === 'WeatherWidget')
    );
  }

  private updateDashboardWidget(widgets: IWidgetImpl[], updateWidgets: IWidgetImpl | IWidgetImpl[], updatedWidgetIdx: number) {
    // leave the update widget out, if it exists
    const isArray = Array.isArray(updateWidgets);
    const updateWidgetsArr = updateWidgets as IWidgetImpl[];
    if (updatedWidgetIdx > -1) {
      widgets = isArray
        ? [...widgets.slice(0, updatedWidgetIdx), ...updateWidgetsArr, ...widgets.slice(updatedWidgetIdx + 1)]
        : [...widgets.slice(0, updatedWidgetIdx), updateWidgets, ...widgets.slice(updatedWidgetIdx + 1)];
    } else {
      widgets = isArray ? [...widgets, ...updateWidgetsArr] : [...widgets, updateWidgets];
    }

    return widgets;
  }

  private extractUpdateDashboard(dashboard: IDashboardImpl) {
    const { organizationId, id, owner, createdAt, createdBy, updatedAt, updatedBy, ...updateDashboard } = { ...dashboard };

    return {
      id,
      updateDashboard,
    };
  }

  private updateDealerWidgetWithNewDealer(widgets: IWidgetImpl[], dealerId: string | null, widgetId?: string) {
    // find default dealer widget
    const dealerWidgetIdx =
      widgetId != null ? widgets.findIndex((widget) => widget.id === widgetId) : this.findWidget(widgets, 'DealerWidget');

    // create dealer widget with configuration from registration dealer data
    const configuration = writeWidgetConfiguration({ dealerId });
    const updatedDealerWidget =
      dealerWidgetIdx > -1
        ? {
          ...widgets[dealerWidgetIdx],
          configuration,
        }
        : {
          configuration,
          name: 'DealerWidget',
          x: 1,
          y: this.calculateNewYIndexForWidget(widgets) + 1,
          id: uuid.v4(),
        };

    return this.updateDashboardWidget(widgets, updatedDealerWidget, dealerWidgetIdx);
  }

  private findWidget(widgets: IWidgetImpl[], widgetName: string) {
    return widgets?.findIndex((widget) => widget.name === widgetName);
  }

  private calculateNewYIndexForWidget(widgets: IWidgetImpl[]) {
    let y = -1;
    widgets.forEach((widget) => (y = widget?.x === 1 && widget?.y > y ? widget.y : y));
    return y;
  }
}
