import { Injectable, signal, inject } from '@angular/core';
import { Router, NavigationEnd, NavigationError } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, map, take } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { goBackNavItems, sidenavItems } from '../utils/nav-items';

export interface IRouteState {
  name: string;
  url: string;
}

@Injectable()
export class RoutingStateService {
  private router = inject(Router);
  private title = inject(Title);
  private translator = inject(TranslateService);
  readonly previousRoute = signal<IRouteState>({ name: 'left_sidenav.dashboard', url: '/dashboard' });
  readonly currentRoute = signal<IRouteState>({ name: '', url: '' });
  readonly isMigrationPage = signal<boolean>(false);

  constructor() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationError || event instanceof NavigationEnd),
        map((event) => event as NavigationError | NavigationEnd)
      )
      .subscribe((event) => {
        if (event instanceof NavigationError) {
          console.error('Navigation error', event);
          if (
            event.error instanceof Error &&
            (event.error.name === 'ChunkLoadError' ||
              event.error.message.toLowerCase().startsWith('failed to fetch dynamically imported module'))
          ) {
            window.location.assign(event.url);
          }
        }

        if (event instanceof NavigationEnd) {
          this.setPreviousRoute({ ...this.previousRoute(), url: this.currentRoute().url });
          this.setCurrentRoute({ ...this.currentRoute(), url: event.url });

          this.setIsMigrationPage();

          this.setTitle(event);
        }
      });
  }

  private setPreviousRoute(route: IRouteState) {
    this.mapRouteName(route);
    this.previousRoute.set(route);
  }

  private setCurrentRoute(route: IRouteState) {
    this.mapRouteName(route);
    this.currentRoute.set(route);
  }

  private setIsMigrationPage() {
    this.isMigrationPage.set(
      this.router.isActive('migration', {
        paths: 'subset',
        queryParams: 'subset',
        fragment: 'ignored',
        matrixParams: 'ignored',
      })
    );
  }

  private setTitle(event: NavigationEnd) {
    const navigationRoute = sidenavItems.find((item) => event.url.includes(item.route));
    const titleText = navigationRoute?.text || (event.url === '/' ? 'left_sidenav.dashboard' : '');

    this.translator.get(titleText).pipe(filter(text => !text.includes('left_sidenav.')), take(1)).subscribe(translatedTitleText => {
      this.title.setTitle('CLAAS connect | ' + translatedTitleText);
    });
  }

  private mapRouteName(route: IRouteState) {
    const navigationRoute = goBackNavItems.find((item) => route.url.includes(item.route));
    if (navigationRoute) {
      route.name = navigationRoute.text;
    }
  }
}
