import { EventEmitter, Injectable, inject } from '@angular/core';
import { ServiceCall } from './service-call.service';
import { Observable, catchError, from, map, mergeAll, mergeMap, of, toArray } from 'rxjs';
import { IFileTransfer } from '../models/file-transfer.model';
import { environment } from '@environments/environment';
import { Machine } from '../models/machines.model';
import { ServiceCallResponse } from '../models/service-call.model';

export const MACHINES_URL = 'v1/machines';

@Injectable()
export class FileTransferService {
  private serviceCall = inject(ServiceCall);
  private machinesUrl = `${environment.apiBaseUrl}/${MACHINES_URL}`;

  public newFilesUploadedEvent: EventEmitter<any> = new EventEmitter();

  dispatchNewFilesUploadedEvent() {
    this.newFilesUploadedEvent.emit();
  }

  getOutboxFiles(uuid: string): Observable<IFileTransfer[]> {
    return this.serviceCall.get(`${this.machinesUrl}/${uuid}/outbox/files`).pipe(map((response) => response.data));
  }

  getOutboxFilesForMultipleIds(machines: Machine[]): Observable<IFileTransfer[]> {
    return from(machines).pipe(
      mergeMap((machine) =>
        this.getOutboxFiles(machine.id).pipe(
          map((files) => files.map((file) => ({ ...file, machine: machine }))),
          catchError(() => of([]))
        )
      ),
      mergeAll(),
      toArray()
    );
  }

  getInboxFiles(uuid: string): Observable<IFileTransfer[]> {
    return this.serviceCall.get(`${this.machinesUrl}/${uuid}/inbox/files`).pipe(map((response) => response.data));
  }

  getInboxFilesForMultipleIds(machines: Machine[]): Observable<IFileTransfer[]> {
    return from(machines).pipe(
      mergeMap((machine) =>
        this.getInboxFiles(machine.id).pipe(
          map((files) => files.map((file) => ({ ...file, machine: machine }))),
          catchError(() => of([]))
        )
      ),
      mergeAll(),
      toArray()
    );
  }

  downloadInboxFileById(machineId: string, fileId: string) {
    return this.serviceCall
      .getOctetStream(`${this.machinesUrl}/${machineId}/outbox/files/${fileId}`)
      .pipe(map((response) => response as ServiceCallResponse));
  }

  deleteInboxFileById(machineId: string, fileId: string) {
    return this.serviceCall.delete(`${this.machinesUrl}/${machineId}/outbox/files/${fileId}`).pipe(map((response) => response.data));
  }

  sendFile(machineId: string, data: ArrayBuffer) {
    return this.serviceCall.postOctetStream(`${this.machinesUrl}/${machineId}/inbox/files`, data).pipe(map((response) => response.data));
  }
}
