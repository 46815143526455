import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as LicenseActions from './license.actions';
import { catchError, finalize, map, of, switchMap } from 'rxjs';
import { LicenseService } from '@app/shared/services/license.service';
import { Store } from '@ngrx/store';
import * as RootState from '../';
import { IOrganizationLicense, IOrganizationLicensesContainer } from '@app/shared/models/license.model';
import { TOASTR_ALERT_OPTIONS } from '@app/cde-toast/utils/toastr-alert-options';
import { AlertService } from '@app/cde-toast/services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { ToasterPosition, ToasterType } from '@app/cde-toast/utils/toastr-options';

@Injectable()
export class LicenseEffects {
  private actions$ = inject(Actions);
  private alertService = inject(AlertService);
  private licenseService = inject(LicenseService);
  private store = inject<Store<RootState.IState>>(Store<RootState.IState>);
  private translate = inject(TranslateService);
  alertOptions = {
    ...TOASTR_ALERT_OPTIONS,
    timeOut: 3000,
  };

  getFarmLicenses$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LicenseActions.GET_FARM_CONNECT_LICENSES),
      switchMap((data: { organizationId: string; language: string }) =>
        this.licenseService.getFarmLicenses(data.organizationId, data.language).pipe(
          map((farmLicenses: IOrganizationLicensesContainer) => new LicenseActions.GetFarmConnectLicensesSuccess(farmLicenses)),
          catchError((error) => of(new LicenseActions.GetFarmConnectLicensesFailure(error))),
          finalize(() => this.store.dispatch(new LicenseActions.GetFarmConnectLicensesComplete()))
        )
      )
    )
  );

  activateOrganizationLicense$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LicenseActions.ACTIVATE_ORGANIZATION_LICENSE),
      switchMap((data: { organizationId: string; licenseId: string; language: string }) =>
        this.licenseService.activateOrganizationLicense(data.organizationId, data.licenseId, data.language).pipe(
          map((farmLicense: IOrganizationLicense) => {
            this.alertService.callSnackbarSuccess(
              this.translate.instant('license.farm.license_activation.success'),
              '',
              ToasterType.snackbarSuccess,
              {
                positionClass: ToasterPosition.bottomCenter,
              }
            );

            return new LicenseActions.ActivateOrganizationLicenseSuccess(farmLicense);
          }),
          catchError((error) => {
            this.alertService.showError(
              this.translate.instant('license.farm.license_activation.failure'),
              this.translate.instant('alert_message.try_again'),
              'alert-error-icon',
              this.alertOptions
            );

            return of(new LicenseActions.ActivateOrganizationLicenseFailure(error));
          }),
          finalize(() => this.store.dispatch(new LicenseActions.ActivateOrganizationLicenseComplete()))
        )
      )
    )
  );
}
