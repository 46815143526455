import { Injectable, inject } from '@angular/core';
import { map } from 'rxjs/operators';
import { ServiceCall } from './service-call.service';
import { CDESyncCouplingsResponse } from '@app/shared/models/cde-sync-couplings.model';
import { environment } from '@environments/environment';

export const COUPLINGS_URL = 'v1/couplings';
@Injectable({
  providedIn: 'root',
})
export class CouplingsService {
  private serviceCall = inject(ServiceCall);
  private couplingsUrl = `${environment.apiBaseUrl}/${COUPLINGS_URL}`;

  getCouplings(timeZone: string) {
    return this.serviceCall
      .get(`${this.couplingsUrl}?timeZone=${timeZone}`)
      .pipe(map((response) => response.data.content as CDESyncCouplingsResponse));
  }

  postCouplings(partnerID: string) {
    return this.serviceCall
      .post(`${this.couplingsUrl}`, { partnerId: partnerID })
      .pipe(
        // map((response) => new CouplingsResponse(response.data.content))
        map((response) => response.data)
      );
  }

  deleteCoupling(couplingId: string) {
    return this.serviceCall
      .delete(`${this.couplingsUrl}/${couplingId}`)
      .pipe(map((response) => response.data));
  }

  getCouplingDetails(couplingId: string) {
    return this.serviceCall
      .get(`${this.couplingsUrl}/${couplingId}`)
      .pipe(map((response) => response.data));
  }
}
