import { Injectable, inject } from '@angular/core';

import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { map, catchError, finalize, mergeMap, tap } from 'rxjs/operators';

import * as RootState from '@app/shared/state';
import * as MarketingPermissionActions from './marketing-permission.actions';

import { MarketingPermissionService } from '@app/shared/services/marketing-permission.service';
import { IMarketingPermission } from 'cde-fe-organization-registration-dialog';

@Injectable()
export class MarketingPermissionEffects {
  private store = inject<Store<RootState.IState>>(Store<RootState.IState>);
  private actions$ = inject(Actions);
  private marketingPermissionService = inject(MarketingPermissionService);
  getClaasMarketingPermission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MarketingPermissionActions.GET_CLAAS_MARKETING_PERMISSION),
      mergeMap(() =>
        this.marketingPermissionService.getClaasMarketingPermission().pipe(
          map(
            (marketingPermission: IMarketingPermission) =>
              new MarketingPermissionActions.GetClaasMarketingPermissionSuccess(marketingPermission)
          ),
          catchError((error) => of(new MarketingPermissionActions.GetClaasMarketingPermissionFailure(error))),
          finalize(() => {
            this.store.dispatch(new MarketingPermissionActions.GetClaasMarketingPermissionComplete());
          })
        )
      )
    )
  );

  getDealerMarketingPermission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MarketingPermissionActions.GET_DEALER_MARKETING_PERMISSION),
      map((action: MarketingPermissionActions.GetDealerMarketingPermission) => ({ dealerId: action.dealerId, language: action.language })),
      mergeMap(({ dealerId, language }) =>
        this.marketingPermissionService.getDealerMarketingPermission(dealerId, language).pipe(
          map(
            (marketingPermission: IMarketingPermission) =>
              new MarketingPermissionActions.GetDealerMarketingPermissionSuccess(marketingPermission)
          ),
          catchError((error) => of(new MarketingPermissionActions.GetDealerMarketingPermissionFailure(error, dealerId))),
          finalize(() => {
            this.store.dispatch(new MarketingPermissionActions.GetDealerMarketingPermissionComplete());
          })
        )
      )
    )
  );

  createClaasMarketingPermission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MarketingPermissionActions.CREATE_CLAAS_MARKETING_PERMISSION),
      map((action: MarketingPermissionActions.CreateClaasMarketingPermission) => action.request),
      mergeMap((request: IMarketingPermission) =>
        this.marketingPermissionService.createClaasMarketingPermission(request).pipe(
          map(() => new MarketingPermissionActions.CreateClaasMarketingPermissionSuccess(request)),
          catchError((error) => of(new MarketingPermissionActions.CreateClaasMarketingPermissionFailure(error))),
          finalize(() => {
            this.store.dispatch(new MarketingPermissionActions.CreateClaasMarketingPermissionComplete());
          })
        )
      )
    )
  );

  createDealerMarketingPermission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MarketingPermissionActions.CREATE_DEALER_MARKETING_PERMISSION),
      map((action: MarketingPermissionActions.CreateDealerMarketingPermission) => action.request),
      mergeMap((request) =>
        this.marketingPermissionService.createDealerMarketingPermission(request).pipe(
          map(() => new MarketingPermissionActions.CreateDealerMarketingPermissionSuccess(request)),
          catchError((error) => of(new MarketingPermissionActions.CreateDealerMarketingPermissionFailure(error))),
          finalize(() => {
            this.store.dispatch(new MarketingPermissionActions.CreateDealerMarketingPermissionComplete());
          })
        )
      )
    )
  );
}
