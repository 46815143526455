import { Injectable, inject } from '@angular/core';
import { map } from 'rxjs/operators';
import {
  ILastMissedMaintenance,
  IMachineMaintenance,
  ILastPerformedMaintenance,
  IGetNextMaintenanceResponse,
} from '../models/machines.model';
import { ServiceCall } from './service-call.service';
import { environment } from '@environments/environment';

export const MAINTENANCE_URL = 'v2/equipment';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceService {
  private serviceCall = inject(ServiceCall);
  private maintenanceUrl = `${environment.apiBaseUrl}/${MAINTENANCE_URL}`;

  getMaintenence(machineUuid: string, operatingHours: string) {
    return this.serviceCall
      .get(`${this.maintenanceUrl}/${machineUuid}/maintenances?operatingHours=${operatingHours}`)
      .pipe(map((response) => response.data as IMachineMaintenance));
  }

  getNextMaintenence(machineUuid: string, operatingHours: string) {
    return this.serviceCall
      .get(`${this.maintenanceUrl}/${machineUuid}/maintenances/next?operatingHours=${operatingHours}`)
      .pipe(map((response) => response.data as IGetNextMaintenanceResponse));
  }

  getLastMissedMaintenence(machineUuid: string) {
    return this.serviceCall
      .get(`${this.maintenanceUrl}/${machineUuid}/maintenances/missed`)
      .pipe(map((response) => response.data as ILastMissedMaintenance));
  }

  getLastPerformedMaintenence(machineUuid: string) {
    return this.serviceCall
      .get(`${this.maintenanceUrl}/${machineUuid}/maintenances/last`)
      .pipe(map((response) => response.data as ILastPerformedMaintenance));
  }
}
