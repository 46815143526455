import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceCall } from './service-call.service';
import { filter, map, take } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { IMaintenanceText } from '../models/dashboard.model';
import { LocalStorageService } from './local-storage.service';
import { User } from 'cde-fe-organization-registration-dialog';
import { Store } from '@ngrx/store';
import { IState } from '../state';
import { userSelector } from '../state/auth';

export const MAINTENANCE_TEXTS_URL = 'v1/maintenance-texts';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceTextService {
  private serviceCall = inject(ServiceCall);
  private localStorageService = inject(LocalStorageService);
  private store = inject<Store<IState>>(Store<IState>);
  private maintenanceTextsUrl = `${environment.apiBaseUrl}/${MAINTENANCE_TEXTS_URL}`;

  getMaintenanceTextsCall(language: string): Observable<IMaintenanceText[]> {
    return this.serviceCall.get(`${this.maintenanceTextsUrl}?language=${language}`).pipe(map((response) => response.data));
  }

  getMaintenanceTexts() {
    this.store
      .select(userSelector)
      .pipe(
        filter((user: User | null): user is User => user !== null),
        take(1)
      )
      .subscribe((user: User) => {
        let lang = user.user_metadata.language;
        lang = lang ? lang : 'de';

        this.getMaintenanceTextsCall(lang)
          .pipe(take(1))
          .subscribe((maintenanceTexts) => {
            return this.localStorageService.setItem('maintenanceTexts', maintenanceTexts);
          });
      });
  }
}
